<template>
    <section>
        <h2 class="h4">
            <Icon v="spell-check" />
            Odmiana:
        </h2>

        <div class="table-responsive">
            <table class="table table-sm">
                <thead>
                    <tr>
                        <th>Mianownik</th>
                        <th>Dopełniacz</th>
                        <th>Celownik</th>
                        <th>Biernik</th>
                        <th>Narzędnik</th>
                        <th>Miejscownik</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td><MorphemeWithPronunciation :pronoun="selectedPronoun" morpheme="pronoun_n" :counter="counter" /></td>
                        <td><MorphemeWithPronunciation :pronoun="selectedPronoun" morpheme="pronoun_g" :counter="counter" /> / <MorphemeWithPronunciation :pronoun="selectedPronoun" morpheme="pronoun_g_acc" :counter="counter" /> / <MorphemeWithPronunciation :pronoun="selectedPronoun" morpheme="pronoun_g_prep" :counter="counter" /></td>
                        <td><MorphemeWithPronunciation :pronoun="selectedPronoun" morpheme="pronoun_d" :counter="counter" /> / <MorphemeWithPronunciation :pronoun="selectedPronoun" morpheme="pronoun_d_acc" :counter="counter" /> / <MorphemeWithPronunciation :pronoun="selectedPronoun" morpheme="pronoun_d_prep" :counter="counter" /></td>
                        <td><MorphemeWithPronunciation :pronoun="selectedPronoun" morpheme="pronoun_a" :counter="counter" /> / <MorphemeWithPronunciation :pronoun="selectedPronoun" morpheme="pronoun_a_acc" :counter="counter" /> / <MorphemeWithPronunciation :pronoun="selectedPronoun" morpheme="pronoun_a_prep" :counter="counter" /></td>
                        <td><MorphemeWithPronunciation :pronoun="selectedPronoun" morpheme="pronoun_i" :counter="counter" /></td>
                        <td><MorphemeWithPronunciation :pronoun="selectedPronoun" morpheme="pronoun_l" :counter="counter" /></td>
                    </tr>
                </tbody>
            </table>
        </div>

        <p class="small">
            Skąd w polszczyźnie tyle różnych form zaimków? Zapraszamy do lektury artykułu:
            <nuxt-link to="/jak-działają-zaimki">
                Co warto wiedzieć o używaniu (i tworzeniu) zaimków?
            </nuxt-link>
        </p>

        <div class="table-responsive">
            <table class="table table-sm">
                <thead>
                    <tr>
                        <th>1 os.</th>
                        <th>2 os.</th>
                        <th>3 os.</th>
                        <th>Przymiotniki</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>
                            <MorphemeWithPronunciation
                                :pronoun="selectedPronoun"
                                morpheme="verb_middle_inter"
                                :counter="counter"
                                prepend="–"
                                :append="selectedPronoun.isPlural(counter) ? 'śmy' : 'm'"
                                :append-pr="selectedPronoun.isPlural(counter) ? 'ɕmɨ' : 'm'"
                            />
                        </td>
                        <td>
                            <MorphemeWithPronunciation
                                :pronoun="selectedPronoun"
                                morpheme="verb_middle_inter"
                                :counter="counter"
                                prepend="–"
                                :append="selectedPronoun.isPlural(counter) ? 'ście' : 'ś'"
                                :append-pr="selectedPronoun.isPlural(counter) ? 'ɕʨ̑ɛ' : 'ɕ'"
                            />
                        </td>
                        <td><MorphemeWithPronunciation :pronoun="selectedPronoun" morpheme="verb_end_about" :counter="counter" prepend="–" /></td>
                        <td><MorphemeWithPronunciation :pronoun="selectedPronoun" morpheme="adjective_n" :counter="counter" prepend="–" /></td>
                    </tr>
                </tbody>
            </table>
        </div>
    </section>
</template>

<script>
export default {
    props: {
        selectedPronoun: { required: true },
        counter: { required: true },
    },
};
</script>
